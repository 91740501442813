<template>
    <router-view></router-view>
</template>
<script>
export default {
    name: 'statement',
}
</script>
<style lang="less">
// 报表 默认3层级 ui
.report-table-3 {
    th {
        text-align: center !important;
    }
    .el-table__body .el-table__row td {
        color: #409eff;
    }
    .el-table__body .el-table__row--level-1 td {
        color: #67c23a;
    }
    .el-table__body .el-table__row--level-2 td {
        color: #666;
    }
    .el-table__body .hover-row.el-table__row td,
    .el-table__body .el-table__row:hover td.el-table__cell {
        color: #000;
        background-color: rgba(64, 158, 255, 0.3);
    }
    .el-table__body .hover-row.el-table__row--level-1 td,
    .el-table__body .el-table__row--level-1:hover td.el-table__cell {
        background-color: rgba(103, 194, 58, 0.2);
    }
    .el-table__body .hover-row.el-table__row--level-2 td,
    .el-table__body .el-table__row--level-2:hover td.el-table__cell {
        background-color: rgb(230, 230, 230, 0.1);
    }
}
.report-table-2 {
    th {
        text-align: center !important;
    }
    .el-table__body .el-table__row td {
        color: #409eff;
    }
    .el-table__body .el-table__row--level-1 td {
        color: #666;
    }
    .el-table__body .hover-row.el-table__row td,
    .el-table__body .el-table__row:hover td {
        color: #000;
        background-color: rgba(64, 158, 255, 0.3);
    }
    .el-table__body .hover-row.el-table__row--level-1 td,
    .el-table__body .el-table__row--level-1:hover td {
        background-color: rgb(230, 230, 230, 0.1);
    }
}

.report-table-1 {
    th {
        text-align: center !important;
    }
    .el-table__body .el-table__row td {
        color: #666;
    }
    .el-table__body .hover-row.el-table__row td,
    .el-table__body .el-table__row:hover td {
        color: #000;
        background-color: rgb(230, 230, 230, 0.1);
    }
}
</style>
